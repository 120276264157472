<template>
  <RequestCard
    :request="request"
    :is-submitting="isSubmitting"
    @submit="onSubmit"
  >
    <template #content>
      <VLine :label="$t('app.attestations')">
        <template #value>
          <div class="cursor-pointer" @click="onClickRedirectToAttestation">
            {{ getText(request.attestation?.texts, "name") }}
          </div>
        </template>
      </VLine>
    </template>

    <template #form>
      <VFile v-model="v.file_ids.$model" :errors="v.file_ids.$errors" multiple>
        <template #label="{ id }">
          <VLine :for="id" :label="$t('app.files', 2)" />
        </template>
      </VFile>

      <VSwitch
        v-model="v.decision.$model"
        true-value="Approved"
        false-value="Rejected"
        :errors="v.decision.$errors"
      >
        <template #label="{ id }">
          <VLine :for="id" :label="$t('app.upload_as')" />
        </template>
      </VSwitch>

      <VComment
        v-model="v.instructor_comments.$model"
        class="col-span-2"
        :label="$t('app.instructor_comment')"
      />
    </template>
  </RequestCard>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { required } from "@/i18n/i18n-validators";
// Composables
import useTexts from "@/composables/useTexts";
import useForm from "@/composables/useForm";
import useRequest from "@/composables/useRequest";
// Components
import RequestCard from "@/views/teaching/requests/RequestCard";
import VLine from "@/components/VLine";
import VSwitch from "@/components/inputs/VSwitch";
import VComment from "@/components/inputs/VComment";
import VFile from "@/components/inputs/VFile";

export default {
  components: {
    RequestCard,
    VLine,
    VSwitch,
    VFile,
    VComment
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, context) {
    // Misc
    const router = useRouter();

    // Data
    const value = ref({
      instructor_comments: props.request.instructor_comments,
      file_ids: props.request.files,
      decision: "Approved"
    });
    const rules = ref({
      instructor_comments: {},
      file_ids: { required },
      decision: { required }
    });

    // Composables
    const { isRequesting: isSubmitting, request } = useRequest();
    const { getText } = useTexts();
    const { v } = useForm(props, context, {
      value,
      rules
    });

    // Methods
    const onClickRedirectToAttestation = () => {
      router.push({
        name: "teaching-attestations-update",
        params: {
          id: props.request.attestation.id
        }
      });
    };

    const onSubmit = async () => {
      const isValid = await v.value.$validate();

      if (!isValid) {
        return;
      }

      await request({
        endpoint: "teaching.attestations.submission.update",
        pathParams: { id: props.request.id },
        data: {
          instructor_comments: value.value.instructor_comments,
          decision: value.value.decision,
          file_ids: value.value.file_ids?.toString() ?? ""
        }
      });

      context.emit("refresh");
    };

    return {
      onClickRedirectToAttestation,
      onSubmit,
      // useForm
      v,
      // useTexts
      getText,
      // useRequest
      isSubmitting
    };
  }
};
</script>
