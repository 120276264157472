<template>
  <div>
    <VTitle class="flex justify-baseline items-center route-title">
      {{ $t("app.attestations", 2) }}

      <VHint class="ml-4 text-base">
        {{ $t("hints.teaching_requests_attestations") }}
      </VHint>
    </VTitle>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert
      v-else-if="data.length === 0"
      :text="$t('app.no_submission_requests')"
    />

    <div v-else class="grid gap-8">
      <Attestation
        v-for="request in data"
        :key="request.id"
        :request="request"
        @refresh="getData"
      />
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import Attestation from "./Attestation";
import VHint from "@/components/VHint";
import VTitle from "@/components/VTitle";
import VAlert from "@/components/VAlert";

export default {
  components: {
    Attestation,
    VHint,
    VTitle,
    VAlert
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Composables
    const { data, isLoading, read } = useReadMultiple();

    // Constants
    // eslint-disable-next-line
    const documentTitle = `${t("app.attestations", 2)} - ${t("app.requests", 2)} - ${t("app.teaching")}`;

    // Methods
    const getData = async () => {
      await read({
        endpoint: "teaching.attestations.requests.submissions"
      });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      // useReadMultiple
      data,
      isLoading,
      getData
    };
  }
};
</script>
